import React from 'react'
import cx from 'classnames'

import CustomLink from '@components/link'
import Photo from './photo'
import { ProductPrice } from './product'

const Label = ({ text, dark }) => {
  return <div className={`pill is-sm ${dark ? '' : 'is-white'}`}>{text}</div>
}

const ProductSlide = ({ slide }) => {
  const {
    link: {
      product,
      product: { title, artist, photos },
    },
  } = slide

  const { label, backgroundColor, backgroundImage, backgroundOpacity } =
    slide.styling || {}

  let bgColorStyle = backgroundColor
    ? { backgroundColor: backgroundColor?.hex }
    : {}
  const bgImageStyle = backgroundOpacity
    ? { opacity: 1 - backgroundOpacity / 100 }
    : {}
  const image = backgroundImage || photos.product[0]?.photo

  const getTitle = () => {
    if (slide.title) return slide.title
    return `${title}${artist?.title ? `\nby ${artist.title}` : ''}`
  }

  return (
    <div
      className={cx(
        'spotlight',
        { 'is-centered': slide.centeredProduct },
        { 'is-white': slide.styling.dark }
      )}
      style={bgColorStyle}
    >
      <CustomLink
        link={{ ...slide.link, title: '' }}
        className="spotlight--link"
      />
      {!slide.centeredProduct && (
        <Photo
          photo={image}
          layout="spotlight"
          className="spotlight--background"
          style={bgImageStyle}
        />
      )}
      <div className="spotlight--container">
        <div className="spotlight--header">Spotlight</div>
        <div className="spotlight--content">
          {!slide.centeredProduct ? (
            <div className="w-full">
              <h2 className="spotlight--content--title whitespace-pre-line">
                {getTitle()}
                {label && <Label text={label} dark={slide.styling.dark} />}
              </h2>
            </div>
          ) : (
            <div className="photo-dropshadow">
              {image && <Photo photo={image} layout="spotlight-product" />}
            </div>
          )}
        </div>

        <p className="spotlight--footer">
          {slide.centeredProduct && label && (
            <Label text={label} dark={slide.styling.dark} />
          )}
          <br />
          {title}
          {artist && (
            <>
              <br />
              <CustomLink
                link={{ page: artist }}
                className="relative z-20 pointer-events-auto"
              >
                By {artist.title}
              </CustomLink>
            </>
          )}
          <br />
          <ProductPrice item={product} prefix="From " />
        </p>
      </div>
    </div>
  )
}

export default ProductSlide
