import React from 'react'
import cx from 'classnames'

import CustomLink from '@components/link'
import Photo from './photo'

const Slide = ({ slide, wide = false, narrow = false, imagePriority }) => {
  const { label, backgroundColor, backgroundImage, backgroundOpacity } =
    slide.styling || {}
  let bgColorStyle = backgroundColor
    ? { backgroundColor: backgroundColor?.hex }
    : {}
  const bgImageStyle = backgroundOpacity
    ? { opacity: 1 - backgroundOpacity / 100 }
    : {}

  const getLabel = label || slide?.link?.page?.type

  const getStyledTitle = () => {
    const { title } = slide?.link || {}
    if (!title) return null
    let i = 0
    let loop = true
    let marks = title

    while (loop) {
      marks = marks.replace('^', i % 2 === 0 ? '<sup>' : '</sup>')
      loop = marks.indexOf('^') > -1
      i++
    }
    return marks
  }

  return (
    <div
      className={cx(
        'spotlight',
        { 'is-white': slide.styling.dark },
        { 'is-lg': wide },
        {
          'is-narrow': narrow,
        }
      )}
      style={bgColorStyle}
    >
      {slide.link?.page && (
        <CustomLink link={slide.link} className="spotlight--link">
          {' '}
        </CustomLink>
      )}

      <Photo
        photo={backgroundImage}
        layout={`spotlight-${wide ? 'lg' : 'background'}`}
        className="spotlight--background"
        style={bgImageStyle}
        imagePriority={imagePriority}
      />
      <div className="spotlight--container">
        <div className="spotlight--content">
          {!slide.centeredProduct && (
            <div className="w-full">
              {getLabel && (
                <div
                  className={`pill is-sm ${
                    !slide.styling.dark ? 'is-white' : ''
                  }`}
                >
                  {getLabel}
                </div>
              )}

              <h2
                className="spotlight--content--title"
                dangerouslySetInnerHTML={{
                  __html: getStyledTitle(),
                }}
              />
              {wide && <p>{slide.text}</p>}
            </div>
          )}
        </div>

        {!wide && <p className="spotlight--footer">{slide.text}</p>}
      </div>
    </div>
  )
}

export default Slide
