import React from 'react'

import Slide from '@components/slide'
import Slider from '@components/slider'
import ProductSlide from '@components/slide-product'

const Spotlight = ({
  data: { slideshow = false, narrow = false, items = [] },
  imagePriority,
}) => {
  if (slideshow)
    return (
      <Slider className="is-tight">
        {items.map((slide, i) => (
          <Slide
            slide={slide}
            key={i}
            wide={true}
            imagePriority={imagePriority}
          />
        ))}
      </Slider>
    )

  return (
    <section className="md:grid grid-cols-2 gap-x-4">
      {items.map((slide, i) => {
        switch (slide._type) {
          case 'slideProduct':
            return <ProductSlide slide={slide} key={i} />
          default:
            return (
              <Slide
                slide={slide}
                key={i}
                head="Spotlight"
                narrow={narrow}
                imagePriority={imagePriority}
              />
            )
        }
      })}
    </section>
  )
}

export default Spotlight
